import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { POSService } from '@/model/api/POS';
import axios from 'axios';
import { CRUD } from './base/crud';

class AdmStatsService extends CRUD<POSService, any> {
    
    public async posNewServices(params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<POSService>>(
            `admstats/pos-new-services-added`, 
            { params, cancelToken }
        );
        
        return res;
    }

    public async posGeoServicesBuyed(params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<POSService>>(
            `admstats/geo-pos-services-buyed`, 
            { params, cancelToken }
        );
        
        return res;
    }

    public async customersGeoServicesBuyed(params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<POSService>>(
            `admstats/geo-customers-services-buyed`, 
            { params, cancelToken }
        );
        
        return res;
    }

}

export const admStatsService = new AdmStatsService();



