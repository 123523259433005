import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { admStatsService } from "@services/admstats.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";

import {
  DynamicTable,
  MyAutocomplete,
} from "@components"
import { authStore } from "@/modules/auth/store";
import moment from "moment";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})

export default class AdmsPosNewServicesPage extends Vue {
  isTableLoading = false;
  totalRecordsLength = 0;
  reloadCount = 0;


  get service() {
    return admStatsService;
  }

  get cols() {
    const columns = [
      {
        field: 'created_at',
        filterField: 'created_at',
        header: this.$t('adminstats.posnewservices.table.inserted_date'),
      },
      {
        field: 'pos_name',
        filterField: 'entity_id',
        header: this.$t('adminstats.posnewservices.table.pos'),
      },
      {
        field: 'service_name',
        filterField: 'service_id',
        header: this.$t('adminstats.posnewservices.table.inserted_service'),
      },
    ];

    return columns;
  }

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters = {
    entity_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    service_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    created_at: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
  };

  get posId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }

  get title() {
    return this.$t("adminstats.posnewservices.title");
  }
  
  customIndexReqTable(params) {
    const clone = JSON.parse(JSON.stringify(params));

    const dateFilter = clone.filters.created_at?.value;

    if (dateFilter) {
      const stringDate = moment(dateFilter).format('YYYY-MM-DD');
      clone.filters.created_at.value = stringDate;
    }

    this.reloadCount++;
    return admStatsService.posNewServices(clone);
  }


  onTableLoadingChange(value: boolean) {
    this.isTableLoading = value;    
  }

  onTableTotalRecordsChange(value: number) {
    this.totalRecordsLength = value;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  applyFilters() {
    this.datatable.loadData();
  }

}